<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs
      v-model="tab"
      background-color="white"
      color="deep-purple accent-4"
      @change="lastTab = tab"
    >
      <v-tab>Основная информация</v-tab>
      <v-tab :disabled="!isExists">
        <v-badge
          v-if="form.items?.length !== 0 && form.items !== null"
          :content="form.items?.length"
          color="red"
        >
          Дочерние блоки
        </v-badge>

        <span v-else> Дочерние блоки </span>
      </v-tab>
      <v-tab-item>
        <div class="container-fluid pt-4">
          <div class="form-subheader">Сведения</div>
          <!--<b-form-group id="input-group-1" label="OID" label-for="input-1">
          <b-form-input
            id="input-1"
            :value="form.oid"
            placeholder="OID"
            disabled
          ></b-form-input>
        </b-form-group>-->
          <b-form>
            <b-form-group
              id="input-group-code"
              label="Код"
              label-for="input-code"
            >
              <b-form-input
                id="input-code"
                v-model="form.Code"
                :state="validateState('Code')"
                placeholder="Код"
              />
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Название блока"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="form.Name"
                :state="validateState('Name')"
                placeholder="Название блока"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-type"
              label="Тип блока"
              label-for="input-type"
            >
              <b-form-select
                v-model="form.ElementTypeID"
                :options="typeBlocksOptions"
                id="input-type"
                :disabled="isExists"
              />
            </b-form-group>

            <b-form-group
              id="input-group-audience"
              label="Аудитория"
              label-for="input-audience"
            >
              <vue-select
                v-model="form.VisibilityTypeID"
                :options="audiencesOptions"
                id="input-audience"
                :settings="{
                  multiple: true,
                  selectionCssClass: 'select-container',
                }"
              />
            </b-form-group>

            <b-form-group label="Текст для приложения" id="input-group-7">
              <b-form-input
                id="input-notes"
                v-model="form.Notes"
                placeholder="Текст для приложения"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Стиль" id="input-group-5">
              <b-form-input
                  id="input-5"
                  v-model="form.JSONStyle"
                  placeholder="Стиль"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Параметры" id="input-group-7">
              <b-form-textarea
                id="input-params"
                v-model="form.JSONParams"
                placeholder="Параметры"
              ></b-form-textarea>
            </b-form-group>



            <b-form-group id="input-group-7">
              <switch-checkbox v-model="form.IsActive" label="Активен" />
            </b-form-group>
          </b-form>
        </div>
      </v-tab-item>
      <v-tab-item>
        <in-publications-parent-list
          :items="form.items"
          :parent-form="form"
          :level="1"
          @saved="$emit('saved')"
          @update-item="$emit('update-item')"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import SwitchCheckbox from '@/view/content/SwitchCheckbox';
import { SAVE_ELEMENT } from '@/core/services/store/publications.module';

export default {
  components: { SwitchCheckbox },

  mixins: [validationMixin],

  props: {
    value: {
      type: Object,
      default: () => null,
    },

    parentId: {
      type: [Number, String],
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
      tab: 0,
      lastTab: 0,
    };
  },

  computed: {
    ...mapGetters(['audiences', 'typeBlocks']),
    audiencesOptions() {
      return this.audiences.map((x) => {
        return { text: x.Name, id: parseInt(x.ID) };
      });
    },

    typeBlocksOptions() {
      return this.typeBlocks.map((x) => {
        return { text: x.Name, value: parseInt(x.ID) };
      });
    },

    isExists() {
      return Boolean(this?.form?.ID);
    },
  },

  validations: {
    form: {
      Name: {
        required,
      },

      Code: {
        required,
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },

    value(v) {
      this.form = v;
      this.tab = this.lastTab;
    }
  },

  mounted() {
    if (!this.isExists) {
      this.clearForm();
    }
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    prepareForm() {
      if (this.form.VisibilityTypeID === null) {
        this.form.VisibilityTypeID = [];
      }

      this.form.VisibilityTypeID = this.form.VisibilityTypeID.map((x) => parseInt(x));
      if (this.parentId === null) {
        if (this.$route.name === 'publications-detail') {
          this.form.GroupID = this.$route.params.id;
        }
      } else {
        this.form.GroupID = this.parentId;
      }
    },

    clearForm() {
      this.form = {
        Name: '',
        Notes: '',
        audience_oid: [],
        is_active: true,
        items: [],
        ElementTypeID: 1,
        ElementTypeCode: '',
        ElementTypeName: '',
        color: '',
        params: '{}',
        SortOrder: 0,
        Code: '',
      };
    },

    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return false;
      } else {
        this.prepareForm();

        const result = await this.$store.dispatch(SAVE_ELEMENT, this.form);

        if (result.error === null) {
          this.$emit('saved');
          Swal.fire({
            title: '',
            text: this.isExists ? 'Сохранено!' : 'Создано!',
            icon: 'success',
            heightAuto: false,
          });
          this.$emit('saved');
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
