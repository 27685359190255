import ApiService from '@/core/services/realapi.service';
import JwtService from '@/core/services/jwt.service';

// action types
export const GET_ITEMS_LIKES = 'getItemsLikes';
export const GET_LIKE = 'getLike';
export const EDIT_LIKE = 'editLike';

const actions = {
    [GET_ITEMS_LIKES]() {
        return ApiService.post(
            '/masp/MAWA1/Reaction/GetReactionList',
            {},
            { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JwtService.getToken()}`
                }
            }
        )
    },
    [GET_LIKE](context, id) {
        return ApiService.post(
            '/masp/MAWA1/Reaction/ReadInstance',
            {ID:id},
            { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JwtService.getToken()}`
                }
            }
        )
    },
    [EDIT_LIKE](context, data={}) {
        return ApiService.post(
            '/masp/MAWA1/Reaction/Save',
            data,
            { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JwtService.getToken()}`
                }
            }
        )
    },
};

const mutations = {
};

export default {
    actions,
    mutations,
};