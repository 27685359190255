import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';
import profile from './profile.module';
import references from './references.module';
import dealers from './dealers.module';
import exportSettings from './exportsettings.module';
import brands from './brands.module';
import likes from './likes.module';
import menu from './menu.module';
import modelGroups from './modelgroups.module';
import modelPhotos from './modelphotos.module';
import models from './models.module';
import actions from './actions.module';
import dashboard from './dashboard.module';
import publications from './publications.module';
import alert from './alert.module';
import uploads from './uploads.module';
import notifications from './notifications.module';
import users from './users.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    references,
    dealers,
    exportSettings,
    brands,
    likes,
    menu,
    modelGroups,
    modelPhotos,
    models,
    actions,
    dashboard,
    publications,
    alert,
    uploads,
    notifications,
    users,
  },
});
