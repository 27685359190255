import ApiService from '@/core/services/realapi.service';
import JwtService from '@/core/services/jwt.service';

// action types
export const GET_USERS_CHANGED = 'getUsersChanged';//Кто уже подменяется
export const USERS_LOG_IN = 'usersLogIn';
export const USERS_LOG_OUT = 'usersLogOut';

export const GET_USERS_FILTER_WHO = 'getUsersFilterWho';//список для фильтрации 1
export const GET_USERS_FILTER_WHOM = 'getUsersFilterWhom';//список для фильтрации 2


// mutation types
export const SET_USERS_CHANGED = 'setUsersChanged';
export const SET_USERS_FILTER_WHO = 'setUsersFilterWho';
export const SET_USERS_FILTER_WHOM = 'setUsersFilterWhom';

const state = {
  usersChangedItems: [],
  usersFilterWho: [],
  usersFilterWhom: [],
};
const actions = {
    [GET_USERS_CHANGED](context) {
        context.commit(SET_USERS_CHANGED, []);
        return new Promise((resolve, reject) => {
                ApiService.post(
                    '/masp/MAWA1/Impersonation/Read',
                    '',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${JwtService.getToken()}`
                        }
                    }
                )
                    .then(({data}) => {
                        if (data) {
                            context.commit(SET_USERS_CHANGED, data?.data);
                            resolve(data);
                        }
                    })
                    .catch(({response}) => {
                        reject(response);
                    });
        });
    },

    [USERS_LOG_IN](context, { UserID, ImpersonatedUserID }) {
        return ApiService.post(
          '/masp/MAWA1/Impersonation/Save',
          {
            "UserID": UserID,
            "ImpersonatedUserID": ImpersonatedUserID
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${JwtService.getToken()}`
            }
          }
        )
    },

    [USERS_LOG_OUT](context, { UserID }) {
            return new Promise((resolve, reject) => {
                ApiService.post(
                    '/masp/MAWA1/Impersonation/Delete',
                    {
                        "UserID": UserID,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${JwtService.getToken()}`
                        }
                    }
                )
                    .then(({data}) => {
                      resolve(data);
                    })
                    .catch(({response}) => {
                        reject(response);
                    });
            });
    },

    [GET_USERS_FILTER_WHO](context, data= {}) {
      context.commit(SET_USERS_FILTER_WHO, []);
      return new Promise((resolve, reject) => {
        ApiService.post(
          '/masp/MAWA1/Impersonation/ReadByParam',
          data,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${JwtService.getToken()}`
            }
          }
        )
          .then(({data}) => {
            if (data) {
              context.commit(SET_USERS_FILTER_WHO, data?.data);
              resolve(data);
            }
          })
          .catch(({response}) => {
            reject(response);
          });
      });
  },

    [GET_USERS_FILTER_WHOM](context, data= {}) {
      context.commit(SET_USERS_FILTER_WHOM, []);
      return new Promise((resolve, reject) => {
        ApiService.post(
          '/masp/MAWA1/Impersonation/ReadByParam',
          data,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${JwtService.getToken()}`
            }
          }
        )
          .then(({data}) => {
            if (data) {
              context.commit(SET_USERS_FILTER_WHOM, data?.data);
              resolve(data);
            }
          })
          .catch(({response}) => {
            reject(response);
          });
      });
  },

};

const mutations = {
    [SET_USERS_CHANGED](state, items) {
        state.usersChangedItems = items || [];
    },
    [SET_USERS_FILTER_WHO](state, items) {
        state.usersFilterWho = items || [];
    },
    [SET_USERS_FILTER_WHOM](state, items) {
        state.usersFilterWhom = items || [];
    },
};

export default {
    state,
    actions,
    mutations,
};