import ApiService from '@/core/services/realapi.service';
import JwtService from '@/core/services/jwt.service';

// action types
export const GET_MENU = 'getMenu';
export const CHECK_ROUTE = 'checkRoute';

const actions = {
    [GET_MENU]() {
        return ApiService.post(
            '/MWA/v1/Menu',
            {
                "root": "MenuAdv"
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer 7584B949B292A2408AA19119B07A9FFA`,
                    'Authorization1': `Bearer ${JwtService.getToken()}`,
                }
            }
        )
    },
    [CHECK_ROUTE](context, link) {
        return ApiService.post(
            '/MWA/v1/UserAccess',
            {
                "route": link
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer 7584B949B292A2408AA19119B07A9FFA`,
                    'Authorization1': `Bearer ${JwtService.getToken()}`
                }
            }
        )
    },
};

export default {
    actions,
};