<template>
  <div
    :style="isDragging && 'border-color: green;'"
    class="dropzone"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
    @click.stop="$refs.file.click()"
  >
    <div v-show="loading" class="loading">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden" />
        </div>
      </div>
    </div>

    <div v-show="file === null && preview === null" class="label-upload">
      <label ref="uploadLabel" class="file-label">
        <div v-if="isDragging">Для загрузки отпустите файл</div>
        <div v-else>
          Перетащите файлы сюда или <span class="click-link">щёлкните</span> на поле, чтобы загрузить.
        </div>
      </label>
    </div>

    <div v-show="(file !== null || preview !== null) && loading === false">
      <div class="preview-image">
        <div class="image-item">
          <img :src="preview" style="max-width: 150px" />
          <button
            type="button"
            class="btn btn-outline-danger delete-button"
            @click.stop="removeFile"
          >
            Удалить
          </button>
        </div>
      </div>
    </div>

    <input
      ref="file"
      :multiple="false"
      type="file"
      id="fileInput"
      class="hidden-input"
      @input="onChange"
      accept=".jpg, .jpeg, .png"
    />
  </div>
</template>

<script>
// import JwtService from '@/core/services/jwt.service';
import {
  GET_UPLOAD_TOKEN,
  UPLOAD_SINGLE_FILE,
} from '@/core/services/store/uploads.module';

export default {
  props: {
    value: {
      type: [Array, Object, String],
      default: () => [],
    },
    options: {
      type: Object,
      default: () => null,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      isDragging: false,
      localValue: this.value,
      file: null,
      preview: null,
      loading: false,
    };
  },

  beforeMount() {
    this.$store.dispatch(GET_UPLOAD_TOKEN);

    if (this.value?.preview) {
      this.preview = this.value.preview;
    }
  },

  methods: {
    async onChange() {

      let content = null;
      this.file = null;
      this.file = this.$refs.file.files[0];
      if(!this.file)
        this.removeFile();

      else {
        this.loading = true;
        content = await this.getBase64(this.file);

        const data = {
          meta: {
            extension: /[.]/.exec(this.file.name)
              ? '.' + /[^.]+$/.exec(this.file.name)?.[0]
              : undefined,
            'content-type': this.file.type,
          },
          content: content.split(',')?.[1],
        };

        const result = await this.$store.dispatch(UPLOAD_SINGLE_FILE, data);

        if (result) {
          const formatedResult = {
            guid: result.id,
            type: result.meta['Content-Type'],
            ext: result.meta.extension,
          };

          this.$emit('input', formatedResult);

          if (content) {
            this.preview = content;
          }
          this.loading = false;
        }
      }
    },

    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },

    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },

    async getBase64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
      });
      return result_base64;
    },

    removeFile() {
      this.file = null;
      this.preview = null;
      this.$refs.file.value = null;
      this.$emit('input', null);
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  padding: 20px;
  border: 2px solid #e5e5e5;
  font-family: Arial, sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  background-color: #f6f6f6;
  min-height: 150px;
  height: auto;

  .label-upload {
    text-align: center;
  }

  .hidden-input {
    display: none;
  }

  .preview-image {
    .image-item {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 150px;
      max-width: 150px;
      align-items: center;
      flex-grow: 0;
      overflow: hidden;

      img {
        position: absolute;
        z-index: 1;
        margin: 0 auto;
        display: block;
      }

      .delete-button {
        position: absolute;
        top: auto;
        bottom: 5px;
        margin: 0 auto;
        z-index: 2;
      }
    }
  }
}
.click-link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
